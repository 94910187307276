const theme = {
  fonts: {
    body: {
      family: 'Gelion, Open Sans, Helvetica, Helvetica Neue, Arial',
      size: '14px',
    },
  },
  text: {
    h1: {
      fontSizeMobile: '3.6rem',
      fontSizeDesktop: '5.6rem',
      lineHeight: 1,
    },
    h3: {
      fontSizeMobile: '2rem',
      fontSizeDesktop: '2.6rem',
      lineHeight: 1.2,
    },
    h4: {
      fontSizeMobile:'1.6rem',
      fontSizeDesktop: '1.6rem',
      lineHeight: 1.2,
    },
    h5: {
      fontSizeMobile: '1.4rem',
      fontSizeDesktop: '1.4rem',
      lineHeight: 1.2,
    },
    p: {
      fontSizeMobile: '1.4rem',
      fontSizeDesktop: '1.4rem',
      lineHeight: 1.2,
    },
    span: {
      fontSizeMobile: '1.4rem',
      fontSizeDesktop: '1.4rem',
      lineHeight: 1,
    },
  },
  colors: {
    primary: '#f42069',
    white: '#ffffff',
    almostWhite: '#eeeeee',
    black: '#030303',
    almostBlack: '#191919',
    darkGray: '#1b1b1b',
    mediumGray: '#7f7f7f',
    disabledGray: '#b6b6b6',
    darkerLightGray: '#d9d9d9',
    lightGray: '#efefef',
    yellow: '#ffc600',
    lightYellow: '#fff6d5',
    blue: '#1e90ff',
    danger: '#fd2f2f',
  },
  spacing: {
    tiny: '0.5rem',
    small: '1rem',
    medium: '1.5rem',
    large: '3rem',
    xlarge: '5rem',
  },
};

export type Theme = typeof theme;
export default theme;
