import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

export interface MediaQueryProps {
  lessThan?: 'small' | 'medium' | 'large' | 'xlarge';
  greaterThan?: 'small' | 'medium' | 'large' | 'xlarge';
}

export default Styled.div<MediaQueryProps>`
  display: none;

  ${props => props.lessThan && Media.lessThan(props.lessThan)`
    display: block;
  `}

  ${props => props.greaterThan && Media.greaterThan(props.greaterThan)`
    display: block;
  `}
`;
