/* Note: Linter does not understand advanced styled components indentation */
/* eslint-disable indent */
import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

import { Theme } from '../../theme';

import {
  GroupConfigurationType,
  GroupProps,
} from './index';

interface StyledGroupProps extends GroupProps {
  theme: Theme;
}

const groupProps = (theme: Theme, configuration: GroupConfigurationType) => {
  const spacing = configuration.spacing ? theme.spacing[configuration.spacing] : '0';
  const halfSpacing = parseFloat(spacing) / 2;

  return `
    ${configuration.stack ? `
      flex-direction: column;
      align-items: stretch;

      ${configuration.expand ? 'justify-content: space-between;' : ''}
      ${configuration.expand ? 'width: 100%;' : ''}
      ${configuration.expand ? 'height: 100%;' : ''}

      & > * {
        ${halfSpacing ? `padding-top: ${halfSpacing}rem;` : ''}
        ${halfSpacing ? `padding-bottom: ${halfSpacing}rem;` : ''}

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    ` : ''}

    ${!configuration.stack ? `
      flex-direction: row;
      
      ${configuration.expand ? 'justify-content: space-between;' : ''}
      ${configuration.expand ? 'flex: 1' : ''}
      
      & > * {
        ${halfSpacing ? `padding-left: ${halfSpacing}rem;` : ''}
        ${halfSpacing ? `padding-right: ${halfSpacing}rem;` : ''}

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    ` : ''}
  `;
};

export const Group = Styled.div<GroupProps>`
  display: flex;
  align-items: center;

  ${Media.lessThan('medium')`
    ${(props: StyledGroupProps) => groupProps(props.theme, {
      ...(props.small || {}),
    })}
  `}

  ${Media.greaterThan('medium')`
    ${(props: StyledGroupProps) => groupProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
    })}
  `}

  ${Media.greaterThan('large')`
    ${(props: StyledGroupProps) => groupProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
    })}
  `}

  ${Media.greaterThan('huge')`
    ${(props: StyledGroupProps) => groupProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
      ...(props.huge || {}),
    })}
  `}
`;
