import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

export const Nav = Styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.almostWhite};
  padding: 2rem;
  height: 6rem;
  line-height: 0.5;

  ${Media.greaterThan('large')`
    padding: 2rem 0;
    max-width: 100rem;
    margin: 0 auto;
  `}
`;

export const Logo = Styled.img`
  height: ${props => props.height}rem;
`;
