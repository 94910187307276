import { createGlobalStyle, ThemeProps } from 'styled-components';
import { Theme } from '../index';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    font-variant-ligatures: no-common-ligatures;
    overflow-x: hidden;
  }
  
  body {
    font-family: ${(props: ThemeProps<Theme>) => props.theme.fonts.body.family};
    font-size: ${(props: ThemeProps<Theme>) => props.theme.fonts.body.size};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  ul {
      list-style: none;
      padding: 0;
  }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }
`;
