import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

export const Footer = Styled.footer`
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: left;
  margin: 6rem 2rem 3rem 2rem;

  ${Media.greaterThan('large')`
    margin: 3rem auto;
    max-width: 100rem;
  `}
`;
