import React from 'react';
import { Helmet } from 'react-helmet';

const BASE_URL = 'https://www.themegenerator.io';

export interface MetaProps {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}

const Meta: React.FC<MetaProps> = React.memo(
  ({
    title = 'Theme Generator',
    description = 'Instantly generate themes and extract design tokens from any source with Theme Generator',
    url,
    image = '/opengraph-2021-06-16.png',
  }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content={`${BASE_URL}${url}`} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${BASE_URL}/${image}`} />

      <link rel="canonical" href={`${BASE_URL}`} />
    </Helmet>
  )
);

export default Meta;
