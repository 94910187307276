import React from 'react';
import { Theme } from '../../theme';

import { Group as StyledGroup } from './styles';

export type GroupConfigurationType = {
  stack?: boolean;
  expand?: boolean;
  spacing?: keyof Theme['spacing'];
};

export interface GroupProps {
  small?: GroupConfigurationType;
  medium?: GroupConfigurationType;
  large?: GroupConfigurationType;
  huge?: GroupConfigurationType;
  children?: React.ReactNode | React.ReactNode[];
}

const Group: React.FC<GroupProps> = ({
  small = { spacing: 'small' },
  medium,
  large,
  huge,
  children,
}) => (
  <StyledGroup small={small} medium={medium} large={large} huge={huge}>
    {React.Children.map(children, (childNode, index) => (
      <div key={index}>{childNode}</div>
    ))}
  </StyledGroup>
);

export default Group;
