import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

export interface TextProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
  weight?: 'normal' | 500 | 600;
  color?: 'black' | 'mediumGray';
  textAlign?: 'left' | 'center' | 'right';
  noMargin?: boolean;
}

export const Text = Styled.p.attrs((props: TextProps) => ({
  as: props.as,
}))<TextProps>`
  font-size: ${props => props.theme.text[props.as].fontSizeMobile};
  line-height: ${props => props.theme.text[props.as].lineHeight};
  font-weight: ${props => props.weight};
  color: ${(props: any) => props.theme.colors[props.color]};
  text-align: ${props => props.textAlign};

  ${props => props.noMargin &&`
    margin-bottom: 0;
  `}

  ${Media.greaterThan('large')`
    font-size: ${(props: any) => props.theme.text[props.as].fontSizeDesktop};
  `}
`;
