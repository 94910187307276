import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'gatsby';

import Group from '../Group';
import Text from '../Text';
import MediaQuery from '../MediaQuery';

import productLogo from '../../images/theme-gen-logo-full.svg';
import uizardLogo from '../../images/logo/primary-light.svg';
import symbol from '../../images/logo/symbol.svg';

import { Nav, Logo } from './styles';

interface NavbarProps {
  onLogoClick?: Dispatch<SetStateAction<string | undefined>>;
}

const Navbar: React.FC<NavbarProps> = ({ onLogoClick }) => (
  <Nav>
    <Link to="/" onClick={() => onLogoClick && onLogoClick(undefined)}>
      <Logo src={productLogo} height={2.8} alt="Theme generator logo" />
    </Link>
    <Group small={{ spacing: 'small' }}>
      <Text as="span" color="mediumGray">
        Powered by
      </Text>
      <a href="https://uizard.io" rel="noopener" target="_blank">
        <MediaQuery lessThan="medium">
          <Logo src={symbol} height={2} alt="Uizard logo" />
        </MediaQuery>
        <MediaQuery greaterThan="medium">
          <Logo src={uizardLogo} height={2} alt="Uizard logo" />
        </MediaQuery>
      </a>
    </Group>
  </Nav>
);

export default Navbar;
