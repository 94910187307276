import * as React from "react"
import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';
import { Footer } from './styles';

const Copyright = Styled.p`
    margin: 0;
    font-size: 1.2rem;
    color: #030303;
`;

const Link = Styled.a`
    display: block;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #030303 !important;

    ${Media.greaterThan('large')`
        margin: auto:
    `}

    &:visited {
        color: #030303 !important;
    }

    &:link {
        color: #030303 !important;
    }

    &:hover {
        color: #1e90ff !important;
    }
`;

const Nav = Styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  ${Media.greaterThan('large')`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

const FooterDiv = ({}) => (
    <Footer>
        <Copyright>
            {new Date().getFullYear()} © Uizard Technologies
            <br />
            All rights reserved.
        </Copyright>
        <Nav>
            <Link href="/about/">About</Link>
            <Link href="/privacy/">Privacy Policy</Link>
            <Link href="/terms-of-service/">Terms of Service</Link>
        </Nav>
    </Footer>
)

export default FooterDiv;
