import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../../theme';

import GlobalStyle from '../../theme/GlobalStyle';

export interface LayoutProps {}

const Layout: React.FC<LayoutProps> = React.memo(({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <React.Fragment>{children}</React.Fragment>
  </ThemeProvider>
));

export default Layout;
